import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
// import { FacebookembededService } from './whatsapp/facebook-embeded.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { NZ_I18N } from 'ng-zorro-antd/i18n';
import { en_US } from 'ng-zorro-antd/i18n';
import { CommonModule, registerLocaleData } from '@angular/common';
import en from '@angular/common/locales/en';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { IconsProviderModule } from './icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { ReactiveFormsModule } from '@angular/forms';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { DemoNgZorroAntdModule } from './ng-zorro-antd.module';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { CapiGuideModule } from './components/modals/capi-guide/capi-guide.module';
import { OnboardingModalModule } from './components/modals/onboarding-modal/onboarding-modal.module';
import { ZohoModule } from '../app/zoho-salesiq/zoho.module';
import { WelcomeModalModule } from './components/modals/welcome-modal/welcome-modal.module'
import { TourBoxModule } from './components/tour-box/tour-box.module';
import { DeviceOsModalModule } from './components/modals/device-os-modal/device-os-modal.module';
import { FlutterwaveModule } from "flutterwave-angular-v3";
import { CreateCampaignModalModule } from './components/modals/createCampaign-modal/createCampaign-modal.module';
import { TwoDigitDecimaNumberDirective } from './common/directives/two_decimal.directive';
import { HeaderModule } from 'src/app/header/header.module';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzDrawerModule } from 'ng-zorro-antd/drawer';
import { PaginatorModule } from "src/app/custom/paginator/paginator.module";
import { LoaderModule } from "src/app/custom/loader/loader/loader.module";
import { ModalComponent } from './modal/modal/modal.component';
import { Router } from "@angular/router";
import { ErrorHandler } from "@angular/core";
import * as Sentry from "@sentry/angular-ivy";

registerLocaleData(en);

@NgModule({
  declarations: [
    AppComponent,
    TwoDigitDecimaNumberDirective,
    ModalComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    IconsProviderModule,
    DemoNgZorroAntdModule,
    NzLayoutModule,
    NzMenuModule,
    ReactiveFormsModule,
    NzBadgeModule,
    NzSpaceModule,
    CapiGuideModule,
    CommonModule,
    OnboardingModalModule,
    ZohoModule,
    WelcomeModalModule,
    TourBoxModule,
    DeviceOsModalModule,
    FlutterwaveModule,
    CreateCampaignModalModule,
    HeaderModule,
    NzIconModule,
    NzDrawerModule,
    PaginatorModule,
    LoaderModule
  ],
  providers: [
    { provide: NZ_I18N, useValue: en_US },
    // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [FacebookembededService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
        provide: ErrorHandler,
        useValue: Sentry.createErrorHandler({
          showDialog: false,
        }),
      }, {
        provide: Sentry.TraceService,
        deps: [Router],
      },
      {
        provide: APP_INITIALIZER,
        useFactory: () => () => {},
        deps: [Sentry.TraceService],
        multi: true,
      },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
