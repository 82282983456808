import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clickGAEvent } from 'src/app/_helpers/ga-events';

@Component({
  selector: 'app-onboarding-modal',
  templateUrl: './onboarding-modal.component.html',
  styleUrls: ['./onboarding-modal.component.css']
})
export class OnboardingModalComponent implements OnInit {

  @Input() open: boolean = false;
  @Output() closeModal = new EventEmitter();
  
  constructor() { }

  ngOnInit(): void {
  }

  handleOk(): void {
    this.open = false;
  }

  handleCancel(): void {
    clickGAEvent("close_modal","onboarding-close-modal_click", "onboarding-modal")
    this.closeModal.emit(false);
    this.open = false;
  }

}
