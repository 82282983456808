import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clickGAEvent } from 'src/app/_helpers/ga-events';

@Component({
  selector: 'app-createCampign-modal',
  templateUrl: './createCampaign-modal.component.html',
  styleUrls: ['./createCampaign-modal.component.css']
})
export class CreateCampaignModalComponent implements OnInit {

  @Input() open: boolean = true;
  @Output() closeModal = new EventEmitter();
  @Output() createCampaignModalEvent = new EventEmitter<string>();
  
  constructor() { }

  ngOnInit(): void {
  }

  handleOk(): void {
    this.open = false;
  }

  handleCancel(): void {
    this.closeModal.emit();
    this.open = false;
  }

  selectOption(value: string) {
    if(value === '1'){
      clickGAEvent("select_audience","target-audience-list_click","campaign-create-campaign-modal","Audience list")
    }
    if(value === '2'){
      clickGAEvent("select_audience","target-custom-audience_click","campaign-create-campaign-modal","Custom audience")
    }
    this.handleCancel();
    this.createCampaignModalEvent.emit(value);
  }

  triggerGAModalClose():void{
    clickGAEvent("close_modal","create-campaign-close-modal_click","campaign-create-campaign-modal")
  }

}
