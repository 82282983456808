import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';

import { map, finalize } from 'rxjs/operators';
import { Router } from '@angular/router';
import { first } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import * as Sentry from '@sentry/angular-ivy';
const baseUrl = `${environment.apiUrl}`;
const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
    // 'X-Frame-Options': 'deny',
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Security-Policy':
    //   "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
  }),
};

const httpOptions2 = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json; charset=UTF-8',
    'client-id': environment.clientid,
    // 'X-Frame-Options': 'deny',
    // 'Access-Control-Allow-Origin': '*',
    // 'Content-Security-Policy':
    //   "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
  }),
};

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';
const CHANNEL_LIST = 'channel-list';
const PUBNUB_PUBLISH_KEY = 'publish_key';
const PUBNUB_SUBSCRIBE_KEY = 'pubnub_subscribe_key';
const TOKEN_BUSINESS = 'auth-business';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private accountSubject: BehaviorSubject<any>;
  public account: Observable<any>;
  private refreshTokenTimeout: any;
  rolesData: any;

  private notificationCount = new BehaviorSubject('');
  currentNotification = this.notificationCount.asObservable();

  private walletbalance = new BehaviorSubject('');
  currentBalance = this.walletbalance.asObservable();

  private walletCurrency = new BehaviorSubject('');
  currentCurrency = this.walletCurrency.asObservable();

  private selectedCountry: BehaviorSubject<string> =
    new BehaviorSubject<string>('Nigeria');
  currentCountry: Observable<string> = this.selectedCountry.asObservable();

  private mobileMenu = new BehaviorSubject(false);
  currentMenu = this.mobileMenu.asObservable();

  private mobileHam = new BehaviorSubject(false);
  currentHam = this.mobileHam.asObservable();

  constructor(private http: HttpClient, private router: Router) {
    this.accountSubject = new BehaviorSubject<any>(null);
    this.account = this.accountSubject.asObservable();
  }

  public get accountValue(): any {
    return this.accountSubject.value;
  }

  changeNotification(count: string) {
    this.notificationCount.next(count);
  }

  changeCountry(country: string) {
    this.selectedCountry.next(country);
  }

  changeBalance(count: string) {
    this.walletbalance.next(count);
    this.saveUser({ ...this.getUser(), account_balance: count });
  }

  changeCurreny(currency: string) {
    this.walletCurrency.next(currency);
  }

  checkIfMobile() {
    let details = navigator.userAgent;
    let regexp = /android|iphone|kindle|ipad/i;
    this.mobileMenu.next(regexp.test(details));
  }

  changeHam(value: boolean) {
    this.mobileHam.next(value);
  }

  login(username: string, password: string) {
    let body = {
      user: {
        contact_email: username,
        password: password,
      },
    };
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-cpaas-tenant-url': `${this.getDomain()}`,
      }),
    };
    return this.http
      .post<any>(`${baseUrl}/login`, body, httpOptions1)
      .pipe(
        map((account) => {
          if (account?.response?.token) {
            this.accountSubject.next(account.response.user);
            this.saveToken(account.response.token);
            this.saveUser(account.response.user);
            this.saveWhatsappChannelList(
              account.response.user.channel_group_ids
            );
            this.saveWhatsappKeys(account.response.user);
            this.getNotificationCount();
            this.getAllwalletsData(account.response.user.business_id);
            this.changeCurreny(account.response.user.currency);
            this.checkIfMobile();
          }
          return account;
        })
      );
  }
  getDomain() {
    let url = window.location.href;
    let urlObj = new URL(url);
    let domain = urlObj.hostname; // backend now wants full url string
    return domain;
  }

  getAllwallets(id: any) {
    return this.http.get(
      `${baseUrl}/wallet/transactions?customer_id=${id}`,
      httpOptions2
    );
  }

  getAllwalletsData(id: any) {
    this.http
      .get(`${baseUrl}/wallet/transactions?customer_id=${id}`, httpOptions2)
      .pipe(first())
      .subscribe({
        next: (responseData: any) => {
          this.changeBalance(responseData[0]?.balance || 0);
        },
        error: (error: any) => {
          Sentry.captureMessage('Error: ' + error);
        },
      });
  }

  logout() {
    // this.stopRefreshTokenTimer();
    window.localStorage.clear();
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.removeItem('auth-token');
    window.localStorage.removeItem('auth-user');
    window.localStorage.removeItem('facebookToken');
    this.accountSubject.next(null);
    this.router.navigate(['/auth/login']);
  }

  getNotificationCount() {
    this.http
      .get(`${baseUrl}/notifications/counts`, httpOptions)
      .pipe(first())
      .subscribe({
        next: (responseData: any) => {
          this.changeNotification(responseData?.response?.unread_count);
        },
        error: (error: any) => {
          Sentry.captureMessage('Error: ' + error);
        },
      });
  }

  public saveBusinessToken(token: string): void {
    window.localStorage.removeItem(TOKEN_BUSINESS);
    window.localStorage.setItem(TOKEN_BUSINESS, token);
  }

  public getBusinessToken(): string | null {
    return window.localStorage.getItem(TOKEN_BUSINESS);
  }

  public saveToken(token: string): void {
    window.localStorage.removeItem(TOKEN_KEY);
    window.localStorage.setItem(TOKEN_KEY, token);
  }

  public getToken(): string | null {
    return window.localStorage.getItem(TOKEN_KEY);
  }

  public saveUser(user: any): void {
    window.localStorage.removeItem(USER_KEY);
    window.localStorage.setItem(USER_KEY, JSON.stringify(user));
  }

  public getUser(): any {
    const user = window.localStorage.getItem(USER_KEY);
    if (user) {
      return JSON.parse(user);
    }
    return {};
  }

  // updatePassword(username: string, password: string) {
  //     return this.http.post<any>(`${baseUrl}/update-password`, { "username": "cdpuser",   "password": "new password" }, httpOptions)
  //         .pipe(map(res => {
  //             return res;
  //         }));
  // }

  resetPassword(username: string) {
    let body = {
      contact_email: username,
    };
    let httpOptions1 = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json; charset=UTF-8',
        'x-cpaas-tenant-url': `${this.getDomain()}`,
      }),
    };
    return this.http
      .post<any>(`${baseUrl}/auth/reset-password`, body, httpOptions1)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  submitNewpassword(body: any) {
    return this.http
      .post<any>(`${baseUrl}/auth/set-password`, body, httpOptions)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  changePassword(body: any) {
    return this.http
      .post<any>(`${baseUrl}/auth/change-password`, body, httpOptions)
      .pipe(
        map((res) => {
          return res;
        })
      );
  }

  // refreshToken() {
  //     return this.http.post<any>(`${baseUrl}/refresh-token`, {}, { withCredentials: true })
  //         .pipe(map((account) => {
  //             this.accountSubject.next(account);
  //             this.startRefreshTokenTimer();
  //             return account;
  //         }));
  // }

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtToken = JSON.parse(atob(this.accountValue.jwtToken.split('.')[1]));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    // this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  // getdashboardCampaigns(startDate : any, endDate : any) {
  //     // return this.http.get<any>(`${baseUrl}/dashboard/campaigns?startDate=Wed, 27 July 2016 13:30:00&endDate=Wed, 28 July 2021 13:30:00`, httpOptions)
  //     return this.http.get<any>(`${baseUrl}/dashboard/campaigns?startDate=${startDate}&endDate=${endDate}`, httpOptions)
  //     .pipe(map(res => {
  //         return res;
  //     }));
  // }

  // getdashboardProfiles(startDate : any, endDate : any) {
  //     return this.http.get<any>(`${baseUrl}/dashboard/get-profiles?startDate=${startDate}&endDate=${endDate}`, httpOptions)
  //     .pipe(map(res => {
  //         return res;
  //     }));
  // }

  // gettotalSpend(startDate : any, endDate : any, id : any) {
  //     return this.http.get<any>(`${baseUrl}/account/analytics/${id}`, httpOptions2)
  //     .pipe(map(res => {
  //         return res;
  //     }));
  // }

  // getdashboardProfileReach(startDate : any, endDate : any) {
  //     return this.http.get<any>(`${baseUrl}/dashboard/get-profiles-reach?startDate=${startDate}&endDate=${endDate}`, httpOptions)
  //     .pipe(map(res => {
  //         return res;
  //     }));
  // }

  // getTheCountryData(body: any) {
  //   return this.http.post<any>(`http://api.ipstack.com/2402:e280:3e14:88:9146:48ac:7b29:4ccb?access_key=3b500dc9db75c4e83b2dd8429e62f286`, body, httpOptions);
  // }

  selfSignup(body: any) {
    return this.http.post<any>(`${baseUrl}/auth/selfsignup`, body, httpOptions);
  }

  resendActivationMail(body: any) {
    // return this.http.post<any>(`${baseUrl}/sendactivationemailselfsignup`, body, httpOptions);
    return this.http.post<any>(
      `${baseUrl}/auth/sendactivationemailselfsignup`,
      body,
      httpOptions
    );
  }

  resendOtp(body: any) {
    return this.http.post<any>(`${baseUrl}/auth/otp`, body, httpOptions);
  }

  verifyOtp(body: any) {
    return this.http.post<any>(`${baseUrl}/auth/verifyotp`, body, httpOptions);
  }

  requestToJoinBusiness(body: any) {
    return this.http.post<any>(`${baseUrl}/requestjoin`, body, httpOptions);
  }

  newBusiness(body: any, token: any) {
    this.saveToken(token);
    return this.http.post<any>(`${baseUrl}/business`, body, httpOptions);
  }

  newSender(body = {}, token: any) {
    return this.http.post(`${baseUrl}/sender-ids`, body, httpOptions);
  }

  getAllIndustries() {
    return this.http.get(`${baseUrl}/industries`, httpOptions);
  }

  getExistingBusiness(uid: any) {
    return this.http.get(`${baseUrl}/user/${uid}`, httpOptions);
  }

  saveWhatsappChannelList(groupIds: any[]) {
    let groupIdString = '';

    if (groupIds) {
      groupIds.forEach((element, i) => {
        groupIdString += element;
        i < groupIds.length - 1 ? (groupIdString += '/') : null;
      });
    }
    window.localStorage.removeItem(CHANNEL_LIST);
    window.localStorage.setItem(CHANNEL_LIST, groupIdString);
  }

  public getWhatsappChannelList() {
    return window.localStorage.getItem(CHANNEL_LIST);
  }

  saveWhatsappKeys(user: any) {
    window.localStorage.removeItem(PUBNUB_PUBLISH_KEY);
    window.localStorage.removeItem(PUBNUB_SUBSCRIBE_KEY);
    window.localStorage.setItem(PUBNUB_PUBLISH_KEY, user.publish_key);
    window.localStorage.setItem(
      PUBNUB_SUBSCRIBE_KEY,
      user.pubnub_subscribe_key
    );
  }

  getPubnubPublichKey() {
    return window.localStorage.getItem(PUBNUB_PUBLISH_KEY);
  }

  getPubnubSubscribeKey() {
    return window.localStorage.getItem(PUBNUB_SUBSCRIBE_KEY);
  }

  getUserRoles() {
    return this.http.get(`${baseUrl}/auth/role`, httpOptions);
  }
}
