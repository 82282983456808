import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { clickGAEvent } from 'src/app/_helpers/ga-events';

@Component({
  selector: 'app-droplist',
  templateUrl: './droplist.component.html',
  styleUrls: ['./droplist.component.css']
})
export class DroplistComponent implements OnInit {

  @Input() data!: any;
  @Input() selected!: any;
  @Input() subData!: any; //checkbox data
  @Input() state: 'open'|'closed' = 'closed';

  @Output() addDevice = new EventEmitter();
  @Output() setActive = new EventEmitter();
  checkedBox = false;
  
  constructor() { }

  ngOnInit(): void {
  }

  truncate(arr: []){
    return arr.slice(0, 10);
  }


  setActiveOption(id: any){
    this.setActive.emit(id);
  }

  onCheckBoxChange($event:any, data: any){
    const payload = {payload: data, status: $event.target.checked}
    this.addDevice.emit(payload);
  }

  onCheckBoxChangeLevel($event:any, data: any){
    const payload = {payload: data, status: $event.target.checked}
    this.addDevice.emit(payload);
    this.checkedBox = !this.checkedBox;
  }

  identify(index: any, item: { _id: any; }){
    return item._id; 
  }

  isSelected(d: any){
    return this.selected.some((sel: any) => sel.id === d._id);
  }


}
