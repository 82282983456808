import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { clickGAEvent } from 'src/app/_helpers/ga-events';

@Component({
  selector: 'app-capi-guide',
  templateUrl: './capi-guide.component.html',
  styleUrls: ['./capi-guide.component.css']
})
export class CapiGuideComponent implements OnInit {
  @Input() open: boolean = false;
  @Output() closeModal = new EventEmitter();

  currentStep = 1;
  steps = [
    {
      title: 'The Facebook Conversions API',
      text: 'See how people interact with your business in more ways than just pixel alone. Such as when purchases are initiated online but completed in a physical shop or through a call centre. This can help you better understand your customers.'
    },
    {
      title: 'How it Works',
      text: 'While a Facebook pixel sends online activity through the web browsers your customers use when they visit your website, the Conversions API lets you send activity directly from your server. This gives you more control over the type of activity you can measure both on and offline.'
    },
    {
      title: 'Set it Up',
      text: 'Create personalised, step-by-step instructions for sending the customer actions that you want to measure through Conversions API, then follow the instructions yourself or send them to a developer to complete implementation. Get started to learn more.'
    }
  ]

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  get stepContent(){
    return this.steps[this.currentStep-1];
  }

  next(){
    const newStep = this.currentStep + 1;
    if(newStep <= this.steps.length){
      clickGAEvent("button_click","next_click","capi-guide-modal")
      this.currentStep = newStep;
    } else {
      clickGAEvent("button_click","got-it_click","capi-guide-modal")
      const route = '/audience/capi-integration';
      this.router.navigate([route]);
      this.handleCancel();
    }
  }

  handleOk(): void {
    this.open = false;
  }

  handleCancel(): void {
    this.closeModal.emit(false);
    this.open = false;
    this.currentStep = 1;
  }

  triggerGAClick(name: string){
    if(name === 'icon'){
      clickGAEvent("close_modal","close-capi-guide-modal_click","capi-guide-modal")
    }
    if(name === 'skip'){
      clickGAEvent("button_click","skip_click","capi-guide-modal")
    }
  }
}
