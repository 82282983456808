import { Inject, Injectable, Renderer2, RendererFactory2 } from '@angular/core';
import { THEMES } from '../_helpers/theme.config';
import { DOCUMENT } from '@angular/common';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {
  private renderer!: Renderer2;
  colors$ = new BehaviorSubject<any>({});


  constructor(@Inject(DOCUMENT) private document: Document, private rendererFactory: RendererFactory2) {
    this.renderer = rendererFactory.createRenderer(null, null);
   }

  setTheme(name = 'access') {

    const theme = THEMES[name as keyof typeof THEMES];
    Object.keys(theme).forEach((key) => {
      // this.renderer.setStyle(this.document.documentElement, `--${key}`, key)
      // this.document.documentElement.style.setProperty(`--${key}`, key);
      // root.style.setProperty(`--${key}`, key);
    });

  }

}
