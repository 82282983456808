import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { debounceTime, switchMap } from 'rxjs/operators';
import { clickGAEvent } from 'src/app/_helpers/ga-events';
import * as Sentry from "@sentry/angular-ivy";

@Component({
  selector: 'app-device-os-modal',
  templateUrl: './device-os-modal.component.html',
  styleUrls: ['./device-os-modal.component.css']
})
export class DeviceOsModalComponent implements OnInit {

  @Input() title!: string;
  @Input() open: boolean = false;
  @Input() data: any = [];
  @Input() subData!: {} | [];
  @Input() selected!: any[];
  @Input() searchResult!: any[];

  @Output() closeModal = new EventEmitter();

  @Output() fetchActiveOptions = new EventEmitter();
  @Output() add = new EventEmitter();
  @Output() remove = new EventEmitter();
  @Output() search = new EventEmitter();

  dataCopy!: any;

  searchMode = false;
  searchChange$ = new BehaviorSubject('');
  searchChangeAsObservable$!: Observable<string>;

  activeItem!: string;
  searchString!: any;


  constructor() { }

  ngOnInit(): void {
    this.searchChangeAsObservable$ = this.searchChange$
      .asObservable()
      .pipe(debounceTime(500));

    this.searchChangeAsObservable$.subscribe((data) => {
      this.sendSearch(data);
    },
    (error: any) => {
      Sentry.captureMessage('Error: ' + error);
    });
    this.dataCopy = this.data;
  }

  handleOk(): void {
    this.open = false;
  }

  handleCancel(): void {
    clickGAEvent("close_modal", "devise-os-close-modal_click", "device-os-modal")
    this.closeModal.emit(false);
    this.open = false;
  }

  addDeviceToSelected($event: any) {
    const { status, payload } = $event;
    if (this.selected.some(selected => selected.id === payload.id)) {
      if (!status) {
        this.remove.emit(payload);
      }
    } else {
      if (status) {
        this.add.emit(payload);
      }
    }
  }

  removeSelected(s: any) {
    this.remove.emit(s);
  }

  done() {
    clickGAEvent("button_click", "done_click", "device-os-modal")
    this.closeModal.emit(false);
    this.open = false;
  }

  setActive(id: string) {
    this.activeItem = id === this.activeItem ? '' : id;
    this.subData = [];
    this.fetchActiveOptions.emit(id);
  }

  isActive(id: string) {
    return this.activeItem === id ? 'open' : 'closed';
  }

  identify(index: any, item: { _id: any; }) {
    return item._id;
  }

  triggerInputSearch(event: any) {
    let timer: any;
    clearTimeout(timer);
    timer = setTimeout(() => {
      this.startSearch(event)
    }, 100)
  }

  startSearch(s: any) {
    this.searchMode = true;
    if(s){
      clickGAEvent("input_search", "device-os-modal-input_search", "device-os-modal", s)
    }
    //search local data first
    this.data = this.dataCopy.filter((dc: any) => {
      return dc._source.name.includes(s);
    })
    if (this.data.length === 0) {
      this.searchChange$.next(s);
      // this.data = this.searchResult.map(s=>({...s,name: s.pathName.split("/")[0]}))
      this.data = this.searchResult;
    }
    if (s.length === 0) {
      this.data = this.dataCopy;
      this.searchMode = false;
    }
  }

  sendSearch(s: any) {
    this.search.emit(s)
  }

}
