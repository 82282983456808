import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

import * as Sentry from "@sentry/angular-ivy";

if (environment.production) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

Sentry.init({
  dsn: "https://e745e77e47cb5d681ef30b06c5ee800e@o4506189644431360.ingest.sentry.io/4506189661863936",
  integrations: [
    new Sentry.BrowserTracing({
      tracePropagationTargets: [
        "dev.terragoncpaas.com",
        /dev.terragoncpaas.com\/.*/,
      ],      
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
    new Sentry.Replay(),
  ],
  tracesSampleRate: 1.0,
  replaysSessionSampleRate: 0.1, 
  replaysOnErrorSampleRate: 1.0, 
});
