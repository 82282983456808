<div class="modal">
    <nz-modal [(nzVisible)]="open" [nzFooter]='null' nzTitle="" nzWidth="538px" nzClassName="campaignModal" (nzOnCancel)="triggerGAModalClose();handleCancel()" (nzOnOk)="handleOk()">
    <ng-container *nzModalContent>
       <h4 nz-typography class="h4Color">Create Campaign</h4>
       <div class="discription">To create your campaign, select your target audience mode</div>
       <div class="audienceBtn a1" (click)="selectOption('1')">
          <div class="imagebox">
             <img
                nz-image
                src="assets/img/list.png"
                alt=""
                />
          </div>
          <div class="contentbox">
             <div class="h5Color">Audience list</div>
             <div class="lastcontent">Select an audience to target from your audience list</div>
          </div>
       </div>
       <div class="audienceBtn a2" (click)="selectOption('2')">
          <div class="imagebox">
             <img
                nz-image
                src="assets/img/settings.png"
                alt=""
                />
          </div>
          <div class="contentbox">
             <div class="h5Color">Custom audience</div>
             <div class="lastcontent">Create a custom audience to target</div>
          </div>
       </div>
    </ng-container>
    </nz-modal>
  </div>