import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class SharedService {
  baseUrl: string = `${environment.apiUrl}`;

  private showCapiModal = new BehaviorSubject(false);
  showCapi = this.showCapiModal.asObservable();

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/json; charset=UTF-8',
    //   'X-Frame-Options': 'deny',
    //   'Access-Control-Allow-Origin': '*',
    //   'Content-Security-Policy':
    //     "script-src https: 'unsafe-inline' 'unsafe-eval';style-src https: 'unsafe-inline' 'unsafe-eval';img-src https: data:;font-src https: data:;",
    }),
  };

  constructor(private http: HttpClient) {}

  getSegmentDetails(id: any) {
    return this.http.get(
      `${this.baseUrl}/audience?segment=${id}`,
      this.httpOptions
    );
  }

  getUniversalInsight(dataSource: string, indexName: string) {
    return this.http.get(
      `${this.baseUrl}/audience?datasource=${dataSource}&index_name=${indexName}`,
      // 'https://dev-prime.terragoncdp.com/audience?datasource=client&index_name=132:28',
      this.httpOptions
    );
  }

  getcurrentCount(body = {}) {
    return this.http.post(
      `${this.baseUrl}/audience/count`,
      body,
      this.httpOptions
    );
  }

  // getUploadedDataReport(id: any){
  //   return this.http.get(
  //     `${this.baseUrl}/audience/upload_insight?report=true&segment=${id}`,
  //     this.httpOptions
  //   );
  // }

  getUploadedDataReport(id: any) {
    return this.http.get(
      `${this.baseUrl}/audience?segment=${id}`,
      this.httpOptions
    );
  }

  getUploadedErrorReport(id: any) {
    return this.http.get(
      `${this.baseUrl}/audience/errors?segment=${id}`,
      this.httpOptions
    );
  }

  getDownloadedReport(id: any) {
    return this.http.get(
      `${this.baseUrl}/audience/detailed?segment=${id}`,
      this.httpOptions
    );
  }

  setShowCapiModal(status: boolean) {
    this.showCapiModal.next(status);
  }
}
